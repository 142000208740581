var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "child-row" }, [
    _c(
      "span",
      { staticClass: "col" },
      [
        _c(
          "a-form-model-item",
          {
            attrs: {
              prop: "typeChannelConfigs[" + _vm.selectIndex + "].groupId",
              rules: {
                required: true,
                message: "请选择消息组",
                trigger: "blur",
              },
            },
          },
          [
            _c(
              "a-select",
              {
                attrs: {
                  "show-search": "",
                  allowClear: "",
                  placeholder: "请输入消息组名称",
                  "default-active-first-option": false,
                  "show-arrow": false,
                  "filter-option": false,
                  loading: _vm.groupLoading,
                  optionFilterProp: "children",
                },
                on: {
                  search: _vm.handleGroupSearch,
                  focus: _vm.handleGroupSearch,
                  change: function (value, option) {
                    return _vm.handleGroupChange(value, option, _vm.selectIndex)
                  },
                },
                model: {
                  value: _vm.form.typeChannelConfigs[_vm.selectIndex].groupId,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.typeChannelConfigs[_vm.selectIndex],
                      "groupId",
                      $$v
                    )
                  },
                  expression: "form.typeChannelConfigs[selectIndex].groupId",
                },
              },
              _vm._l(_vm.groupArray, function (d) {
                return _c(
                  "a-select-option",
                  { key: d.id, attrs: { value: d.id } },
                  [_vm._v(" " + _vm._s(d.groupName))]
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "span",
      { staticClass: "col" },
      [
        _c(
          "a-form-model-item",
          {
            attrs: {
              prop: "typeChannelConfigs[" + _vm.selectIndex + "].appId",
              rules: { required: true, message: "请选择App", trigger: "blur" },
            },
          },
          [
            _c(
              "a-select",
              {
                attrs: {
                  "show-search": "",
                  allowClear: "",
                  placeholder: "请输入app名称",
                  "default-active-first-option": false,
                  "show-arrow": false,
                  "filter-option": false,
                  loading: _vm.appLoading,
                },
                on: {
                  search: _vm.handleAppSearch,
                  focus: _vm.handleAppSearch,
                  change: function (value, option) {
                    _vm.handleAppChange(value, option, _vm.selectIndex)
                    _vm.resetTemplateId(_vm.selectIndex)
                  },
                },
                model: {
                  value: _vm.form.typeChannelConfigs[_vm.selectIndex].appId,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.typeChannelConfigs[_vm.selectIndex],
                      "appId",
                      $$v
                    )
                  },
                  expression: "form.typeChannelConfigs[selectIndex].appId",
                },
              },
              _vm._l(_vm.appArray, function (d) {
                return _c(
                  "a-select-option",
                  { key: d.id, attrs: { value: d.id } },
                  [_vm._v(" " + _vm._s(d.appName))]
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "span",
      { staticClass: "col" },
      [
        _c(
          "a-form-model-item",
          {
            attrs: {
              prop: "typeChannelConfigs[" + _vm.selectIndex + "].templateId",
              rules: { required: true, message: "请选择模板", trigger: "blur" },
            },
          },
          [
            _c(
              "a-select",
              {
                attrs: {
                  allowClear: "",
                  placeholder: "请选择模板",
                  "default-active-first-option": false,
                  "show-arrow": false,
                  "filter-option": false,
                  loading: _vm.templateLoading,
                },
                on: {
                  change: function (value, option) {
                    return _vm.handleTemplateChange(
                      value,
                      option,
                      _vm.selectIndex
                    )
                  },
                },
                model: {
                  value:
                    _vm.form.typeChannelConfigs[_vm.selectIndex].templateId,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.typeChannelConfigs[_vm.selectIndex],
                      "templateId",
                      $$v
                    )
                  },
                  expression: "form.typeChannelConfigs[selectIndex].templateId",
                },
              },
              _vm._l(_vm.templateArray, function (d) {
                return _c(
                  "a-select-option",
                  { key: d.templateId, attrs: { value: d.templateId } },
                  [_vm._v(" " + _vm._s(d.templateName))]
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    !_vm.readOnly
      ? _c(
          "span",
          { staticClass: "delete-btn" },
          [
            _c("a-icon", {
              staticClass: "item-delete",
              attrs: { type: "delete" },
              on: {
                click: function ($event) {
                  return _vm.handleDelete(_vm.selectIndex)
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }