<template>
  <div class="child-row">
    <span class="col">
      <a-form-model-item :prop="`typeChannelConfigs[${selectIndex}].groupId`" :rules="{ required: true, message: '请选择消息组', trigger: 'blur' }">
        <a-select
          show-search
          allowClear
          placeholder="请输入消息组名称"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          @search="handleGroupSearch"
          @focus="handleGroupSearch"
          @change="(value, option) => handleGroupChange(value, option, selectIndex)"
          :loading="groupLoading"
          v-model="form.typeChannelConfigs[selectIndex].groupId"
          optionFilterProp="children"
        >
          <a-select-option v-for="d in groupArray" :key="d.id" :value="d.id"> {{ d.groupName }}</a-select-option>
        </a-select>
      </a-form-model-item>
    </span>
    <span class="col">
      <a-form-model-item :prop="`typeChannelConfigs[${selectIndex}].appId`" :rules="{ required: true, message: '请选择App', trigger: 'blur' }">
        <a-select
          show-search
          allowClear
          placeholder="请输入app名称"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          @search="handleAppSearch"
          @focus="handleAppSearch"
          @change="(value, option) => {handleAppChange(value, option, selectIndex); resetTemplateId(selectIndex)}"
          :loading="appLoading"
          v-model="form.typeChannelConfigs[selectIndex].appId"
        >
          <a-select-option v-for="d in appArray" :key="d.id" :value="d.id"> {{ d.appName }}</a-select-option>
        </a-select>
      </a-form-model-item>
    </span>
    <span class="col">
      <a-form-model-item :prop="`typeChannelConfigs[${selectIndex}].templateId`" :rules="{ required: true, message: '请选择模板', trigger: 'blur' }">
        <a-select
          allowClear
          placeholder="请选择模板"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :loading="templateLoading"
          @change="(value, option) => handleTemplateChange(value, option, selectIndex)"
          v-model="form.typeChannelConfigs[selectIndex].templateId"
        >
          <a-select-option v-for="d in templateArray" :key="d.templateId" :value="d.templateId">
            {{ d.templateName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </span>
    <span class="delete-btn" v-if="!readOnly">
      <a-icon type="delete" class="item-delete" @click="handleDelete(selectIndex)"/>
    </span>
  </div>
</template>

<script>
import { listGroup } from '@/api/message/group'
import { listMessageApp } from '@/api/message/messageApp'
import { listTemplate } from '@/api/message/template'
export default {
  data () {
    return {
      groupLoading: false,
      appLoading: false,
      templateLoading: false,
      groupArray: [],
      appArray: [],
      templateArray: []
    }
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    groupName: {
      type: String,
      default: () => {
        return undefined
      }
    },
    appName: {
      type: String,
      default: () => {
        return undefined
      }
    },
    appId: {
      type: Object,
      default: () => {
        return undefined
      }
    },
    selectIndex: {
      type: Number,
      default: 0
    }
  },
  created() {
    if (this.groupName) {
      this.handleGroupSearch(this.groupName)
    }
    if (this.appName) {
      this.handleAppSearch(this.appName, null, true)
    }
  },
  methods: {
    handleDelete (index) {
      this.$confirm({
        title: '确认要删除这条记录吗?',
        content: '',
        zIndex: 99999,
        onOk: () => {
          // this.typeChannelConfigs[index]
          console.log('delete')
          this.$emit('handleDelete', index)
        },
        onCancel () { }
      })
    },
    handleGroupSearch (value) {
      this.groupLoading = true
      const queryParam = {
        pageNum: 1,
        pageSize: 10,
        groupName: value
      }
      listGroup(queryParam).then(response => {
        this.groupArray = response.rows
        this.groupLoading = false
      })
    },
    handleGroupChange (value, option, index) {
      this.$emit('handelSelectGroup', value, index)
    },
    handleAppSearch (value, option, excuteHandleAppChange) {
      this.appLoading = true
      const queryParam = {
        pageNum: 1,
        pageSize: 10,
        appName: value
      }
      listMessageApp(queryParam).then(response => {
        this.appArray = response.rows
        this.appLoading = false
        if (excuteHandleAppChange) {
          this.handleAppChange(this.appId, null, this.selectIndex)
        }
      })
    },
    handleAppChange (value, option, index) {
      if (value === undefined) {
        this.templateArray = []
        return
      }
      const channelType = this.appArray.find((item) => {
        return item.id === value
      }).channelType
      if (channelType) {
        this.$emit('handelSelectApp', value, index)
      }
      this.templateLoading = true
      const queryParam = {
        pageNum: 1,
        pageSize: 10,
        channelType
      }
      listTemplate(queryParam).then((response) => {
        this.templateArray = response.rows
        this.templateLoading = false
      })
    },
    handleTemplateChange (value, option, index) {
      this.$emit('handelSelectTemplate', value, index)
    },
    resetTemplateId (index) {
      this.form.typeChannelConfigs[index].templateId = undefined
    }
  }
}
</script>

<style scoped>
</style>
<style lang="less" scoped>
.child-row {
  width: 100%;
  display: flex;
  .col {
    flex: 1;
    text-align: center;
    vertical-align: middle;
    padding: 0 4px;
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    .ant-form-item {
      margin-bottom: 2px;
    }
  }
  .col:first-child {
    flex: 1;
    min-width: 280px;
  }
  .delete-btn {
    flex: 0 0 30px;
    border: 0;
    border-left: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.child-row:last-child {
  .col {
    border-bottom: 1px solid #e8e8e8;
  }
}
</style>
